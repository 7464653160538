body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.light-shadow {
	box-shadow: 0px 0px 9px -0px rgb(0 0 0 / 5%);
}

.center {
	text-align: center !important;
}
.center {
	text-align: center !important;
}
.responsive {
	width: 100%;
	overflow: auto;
}

/*Basic Menu Container CSS START*/

.ant-card-body {
	padding: 0px;
}

.__with_100 {
	width: 100%;
}

/* // BLog Add Page  */
.__blog_add_card {
	padding: 0px 10px;
}

.__crm_add_new_btn_wrapper {
	text-align: center;
}
.__crm_add_new_btn_wrapper > button {
	margin: 10px;
}

.pointer {
	cursor: pointer;
}

.w100 {
	width: 130px;
	min-height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.wrapper {
	border: 1px solid #ddd;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
	padding: 2px 4px;
}
